import * as React from "react";
import { Box, Card, Flex, Text } from "@radix-ui/themes";
import { GearIcon } from "@radix-ui/react-icons";
import { AppTitle } from "@repo/ui/AppTitle.tsx";
import { useMe } from "../../../users/meHooks.ts";
import { LogoIcon } from "../../LogoIcon.tsx";
import { sidebarNavItems } from "../nav.tsx";
import { SideNavItem } from "./SideNavItem.tsx";
import { routes } from "../../../routing/router.ts";
import { MeAvatar } from "../../MeAvatar.tsx";

interface Props {
  children?: React.ReactNode;
}

export const SideNav: React.FC<Props> = ({}) => {
  const me = useMe();

  return (
    <Flex
      direction={"column"}
      minHeight={"100dvh"}
      overflowY={"auto"}
      width={"250px"}
      style={{ background: "#fcf2e5" }}
      flexShrink={"0"}
    >
      <Flex
        direction={"row"}
        align={"center"}
        gap={"3"}
        p={"3"}
        style={{
          height: "70px",
        }}
      >
        <LogoIcon scale={0.11} />
        <AppTitle fontSize={"1.7em"} />
      </Flex>
      <Flex direction={"column"} pt={"5"} pl={"2"} gap={"1"} align={"stretch"} flexGrow={"1"}>
        {sidebarNavItems.map((item, i) => (
          <SideNavItem key={i} item={item}>
            {item.label}
          </SideNavItem>
        ))}
      </Flex>
      <a {...routes.settings().link}>
        <Card m={"3"}>
          <Flex direction={"row"} align={"center"} gap={"4"}>
            <Flex direction={"row"} align={"center"} gap={"4"} flexGrow={"1"}>
              <MeAvatar size="2" />
              <Box
                pt={"1"}
                style={{
                  maxWidth: "120px",
                  overflow: "hidden",
                  textWrap: "nowrap",
                  textOverflow: "ellipsis",
                }}
              >
                <Text>{me?.email}</Text>
              </Box>
            </Flex>
            <Box pr={"1"} pt={"1"} style={{ opacity: 0.5 }}>
              <GearIcon />
            </Box>
            {/*<DropdownMenu.Root>*/}
            {/*  <DropdownMenu.Trigger>*/}
            {/*    <Button variant="soft">*/}
            {/*      <ChevronUpIcon />*/}
            {/*    </Button>*/}
            {/*  </DropdownMenu.Trigger>*/}
            {/*  <DropdownMenu.Content>*/}
            {/*    <SignOutButton>*/}
            {/*      <Button>Sign Out</Button>*/}
            {/*    </SignOutButton>*/}
            {/*  </DropdownMenu.Content>*/}
            {/*</DropdownMenu.Root>*/}
          </Flex>
        </Card>
      </a>
    </Flex>
  );
};

import * as React from "react";
import { Button } from "@radix-ui/themes";
import SyntaxHighlighter from "react-syntax-highlighter";
import { solarizedDark } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { useQuery } from "convex-helpers/react/cache/hooks";
import { api } from "@repo/convex/convex/_generated/api";
import { Id } from "@repo/convex/convex/_generated/dataModel";
import { useMutation } from "convex/react";
import { toast } from "sonner";
import { formatDistance } from "date-fns";
import { Section } from "../common/section/Section.tsx";

interface Props {
  itemId: Id<"items">;
}

export const ItemContentSection: React.FC<Props> = ({ itemId }) => {
  const content = useQuery(api.itemContent.findMineForItem, { itemId });
  const requestScrape = useMutation(api.itemContent.requestContentScrape);

  return (
    <Section title={"Scraped Item Content"}>
      <SyntaxHighlighter
        language="markdown"
        style={solarizedDark}
        showLineNumbers={true}
        customStyle={{ height: "300px", margin: 0 }}
      >
        {content?.content ?? ""}
      </SyntaxHighlighter>

      <Button
        disabled={content?.status.kind == "scraping_requested"}
        variant={"outline"}
        onClick={() => {
          requestScrape({ itemId }).catch(toast.error);
          toast.info("Scrape request queued");
        }}
      >
        {content?.status.kind == "scraping_requested"
          ? `Will scrape in ${formatDistance(content.status.scheduledApiCallTime, new Date())}`
          : `Scrape Content`}
      </Button>
    </Section>
  );
};
